//系统识别
function system_confirm(){var userAgentInfo = navigator.userAgent;if(userAgentInfo.indexOf("Win")>-1){return "win"}else if(userAgentInfo.indexOf("Mac")>-1){return "mac"}};function device_confirm(){var userAgentInfo = navigator.userAgent;var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];var flag = true;for (var v = 0; v < Agents.length; v++) {if (userAgentInfo.indexOf(Agents[v]) > 0) {flag = false;break;}}return flag;};
if(system_confirm()=="mac"&&device_confirm()){var isMac=true}
else if(system_confirm()=="win"&&device_confirm()){var isMac=false}
if(device_confirm()==true){var device_id=true}
else if(device_confirm()==false){var device_id=false}else{};
function system_fn(){
  if (isMac == true) {
    $('.mac_btn_box').css({"display": "-webkit-box","display":"-webkit-flex","display":"flex"});
    $('.mac_btn').css({"display":"block"});
    $('.mac_inline').css({"display":"inline-block"});
    $('.mac_active').addClass('active')
    $('.win_btn_box').css({"display":"none"});
    $('.win_btn').css({"display":"none"});
    $('.win_inline').css({"display":"none"});
    $('.win_active').removeClass('active')
  } else if (!isMac == true) {
    $('.win_btn_box').css({"display": "-webkit-box","display":"-webkit-flex","display":"flex"});
    $('.win_btn').css({"display":"block"});
    $('.win_inline').css({"display":"inline-block"});
    $('.win_active').addClass('active')
    $('.mac_btn_box').css({"display":"none"});
    $('.mac_btn').css({"display":"none"});
    $('.mac_inline').css({"display":"none"});
    $('.mac_active').removeClass('active')

  } else if (device_id == false) {
    $('.pc_btn').css({"display":"none"});
  };
};
system_fn();

$.ajax({
    url: "/header.html",
    type: "GET",
    dataType: "html",
    async: false,
    success: function(h){
        $.ajax({
            url: "/footer.html",
            type: "GET",
            dataType: "html",
            async: false,
            success: function(f){
                $('.header_module').html(h)
                $('.footer_module').html(f)
            }
        });
    }
});
$(window).resize(function(){
    if($(window).width()<1200){
        $('.header .toggle').removeClass('active');
        $('.header ul').removeClass('active');
    }
});
$('.header .toggle').click(function(){
    $(this).toggleClass('active');
    $('.header ul').toggleClass('active');
});
function widthFix (){
    $('.header .slider_item ul').each(function(ind,itm){
        $(itm).css('minWidth',$(itm).find('li').eq(0).width());
    });
};
if($(window).width()>1200){
    $('.header .product').hover(function(){
        $(this).addClass('active').siblings('.product').removeClass('active');
    },function(){});
    $('.header').mouseleave(function(){
        $('.header .product').removeClass('active');
    });
    widthFix ();
}else{
    $('.header .product').click(function(){
        $(this).toggleClass('active').siblings('.product').removeClass('active');
    });
};
//会员
//宽度小于1200
if($(window).width()>1200){
    $('.header .login_btn .login_box').hover(function(){
        $('.header .login_btn .login_box ul').show();
    },function(){
        $('.header .login_btn .login_box ul').hide();
    });
}else{
    $('.header .login_box ul').width($(window).width());
    $('.header .login_box').click(function(e){
        e.stopPropagation();
        if($('.header .login_box ul').css("display")=='none'){
                $('.header .login_box ul').show();
        }else{
            $('.header .login_box ul').hide();
        }
    });
}